import {
  Box,
  Button,
  Center,
  chakra,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as RouteLink } from "react-router-dom";
import { OuterBox } from "../components/OuterBox";
import { regularButtonProps } from "../components/RegistrationBox";

function TryIt(props: { text?: string }) {
  return (
    <HStack width="100%" alignItems="left">
      <Button as={RouteLink} to="/" {...regularButtonProps} margin="0">
        {props.text ?? "Ausprobieren!"}
      </Button>
    </HStack>
  );
}

export function HelpPage() {
  return (
    <Box width="100%" backgroundColor="myellow.50">
      <Center textAlign="center">
        <VStack
          width="min(100vw, 40rem)"
          padding={2}
          gap={2}
          fontSize={{ base: "md", md: "xl" }}
          minHeight="100vh"
        >
          <OuterBox name="help.now">
            <TryIt text="Ich will es sofort selbst ausprobieren!" />
          </OuterBox>
          <OuterBox name="help.explain">
            <Text width="100%" textAlign="left">
              Der Märlistadt-Programmplaner dient zur Koordination des gesamten
              Märlistadt-Programms von der Erfassung durch die auftretenden{" "}
              <b>Gäste</b> über die <b>Koordination</b>, Rücksprache mit den
              Ressorts (beispielhaft <b>Abt. Musik</b> und <b>Abt. C&W</b>{" "}
              (Country and Western Days)) bis zur Publikation auf verschiedenen
              Kanälen (wie <b>Webseite</b> oder <b>Layout</b> im Programmheft).
            </Text>
            <Text width="100%" textAlign="left">
              Da es sich bei allen um nebenamtliche Freiwillige handelt, muss
              die Kommunikation auch <b>asynchron</b> erfolgen.
            </Text>
            <Text width="100%" textAlign="left">
              Aus verschiedenen Gründen kann es auch nach der Absegnung durch
              alle Beteiligten noch <b>Änderungen</b> geben (Konflikte mit
              späteren Veranstaltungen, Umplanung durch die Künstler, …). Die
              Beteiligten sollen einen Überblick darüber behalten können; nur im
              Ausnahmefall soll auf Medienbrüche wie Email oder Telefon
              zurückgegriffen werden müssen.
            </Text>
            <TryIt />
          </OuterBox>
          <OuterBox name="help.conclusion">
            <Text width="100%" textAlign="left">
              Der Einsatz dieser Webapp habe den Beteiligten unzählige Stunden
              Arbeit abgenommen. Ohne dieses Werkzeug wäre ganz besonders dieses
              Jahr (aufgrund des knappen Zeitplans) ein Durcheinander zwischen
              den verschiedenen Versionen der Beiträge entstanden, die so aber
              komfortabel und zuverlässig vermieden worden seien.
            </Text>
            <TryIt />
          </OuterBox>
          <OuterBox name="help.workflow">
            <Text width="100%" textAlign="left">
              Ein typischer Ablauf könnte wie folgt aussehen:
            </Text>
            <Box width="100%" alignContent="left" alignItems="left">
              <chakra.ul marginLeft={5} textAlign="left">
                <li>
                  <Text>
                    Der Ressortverantwortliche Musik <b>vereinbart</b>{" "}
                    ausserhalb dieses Tools mit einer Band einen Auftritt
                    inklusive Zeit und Umfang.
                  </Text>
                </li>
                <li>
                  <Text>
                    Aufgrund dieser Abmachung <b>trägt</b> die Vertreterin der
                    Band eine Beschreibung des Auftritts inklusive Bilder im
                    Programmplaner ein.
                  </Text>
                </li>
                <li>
                  <Text>
                    Dies wird der Koordinatorin als neuen Event gemeldet. Sie
                    überprüft, ob der Inhalt und die Beschreibung in{" "}
                    <b>Ordnung</b> sind und ob es mit dem restlichen Programm
                    zusammenpasst. Kleinere Rechtschreibfehler korrigiert sie
                    direkt.
                  </Text>
                </li>
                <li>
                  <Text>
                    Falls Fragen oder Konflikte bestehen, trägt die
                    Koordinatorin dies im <b>Diskussionsbereich</b> zum Event
                    ein. Diese Diskussion ist nur für Mitglieder des OK
                    sichtbar.
                  </Text>
                </li>
                <li>
                  <Text>
                    Die Koordinatorin leitet den Event an den
                    Ressortverantwortlichen weiter, damit er <b>überprüfe</b>,
                    ob es auch den Abmachungen entspreche. Dabei kann er selbst
                    auch Einträge im Diskussionsbereich erstellen.
                  </Text>
                </li>
                <li>
                  <Text>
                    Nach allfälligen Rücksprachen mit der Band <b>bestätigt</b>{" "}
                    der Ressortverantwortliche ob alles stimmt und gibt es je
                    nachdem zurück an die Koordinatorin oder weiter an die
                    Publikation.
                  </Text>
                </li>
                <li>
                  <Text>
                    Der Verantwortliche für das Programm auf der <b>Webseite</b>{" "}
                    sieht, dass ein neuer Beitrag eingegangen ist und kann ihn
                    einfügen. Dabei hilft ihm ein ZIP des neuen Beitrags (oder
                    alternativ: aller neuen Beiträge).
                  </Text>
                </li>
                <li>
                  <Text>
                    Im Normalfall sollten keine weiteren Änderungen mehr nötig
                    sein. Falls doch, sehen die für die Publikation
                    Verantwortlichen, dass ein Einträg nachträglich geändert
                    wurde und können die <b>Korrekturen</b> nachführen.
                  </Text>
                </li>
              </chakra.ul>
            </Box>
            <Text width="100%" textAlign="left">
              Auf Wunsch des OK Märlistadt gibt es relativ wenige Automatismen.
              Der Fokus liegt auf der dokumentierten Zusammenarbeit zwischen
              allen Beteiligten.
            </Text>
            <Text width="100%" textAlign="left">
              Trotz unerwartet hektischem Ablauf, der dafür sorgte, dass viele
              Arbeiten parallel ablaufen mussten, die ursprünglich nacheinander
              vorgesehen waren, waren die Beteiligten sehr zufrieden.
            </Text>
            <TryIt />
          </OuterBox>
          <OuterBox name="help.downloads">
            <Text width="100%" textAlign="left">
              Folgende Downloadmöglichkeiten stehen zur Vereinfachung weiterer
              Prozesse zur Verfügung:
            </Text>
            <Box width="100%" alignContent="left" alignItems="left">
              <chakra.ul marginLeft={5} textAlign="left">
                <li>
                  <Text>
                    <b>ZIP</b>-Download eines einzelnen Events, der neuen Events
                    oder aller Events.
                  </Text>
                </li>
                <li>
                  <Text>
                    <b>CSV</b>-Download aller Ereignisse für den Import z.B. in
                    Excel oder LibreOffice. Aus dieser Liste kann auch die
                    Programmübersicht im Programmheft erstellt werden. Die
                    Differenzbereinigung dieser Liste war in den Vorjahren immer
                    ein riesiger Zeitfresser.
                  </Text>
                </li>
                <li>
                  <Text>
                    <b>Kontakte</b> für das Erstellen eines Adressbuchs, welches
                    die Verantwortlichen während der Märlistadt auf ihrem Handy
                    mitführen können. Damit ist die Last-Minute-Kommunikation
                    gewährleistet.
                  </Text>
                </li>
                <li>
                  <Text>
                    <b>Kalender</b> mit allen Events. Ebenfalls für den Import
                    auf das Handy (oder den Laptop), um den Überblick zu
                    bewahren.
                  </Text>
                </li>
              </chakra.ul>
            </Box>
            <TryIt />
          </OuterBox>
          <OuterBox name="help.technology">
            <Text width="100%" textAlign="left">
              Zum Einsatz kommen insbesondere{" "}
              <a
                style={{ textDecoration: "underline" }}
                href="https://reactjs.org/"
              >
                React
              </a>{" "}
              mit{" "}
              <a
                style={{ textDecoration: "underline" }}
                href="https://chakra-ui.com/"
              >
                Chakra
              </a>
              ,{" "}
              <a
                style={{ textDecoration: "underline" }}
                href="https://nodejs.org/"
              >
                Node.js
              </a>
              ,{" "}
              <a
                style={{ textDecoration: "underline" }}
                href="https://www.typescriptlang.org/"
              >
                TypeScript
              </a>
              ,{" "}
              <a
                style={{ textDecoration: "underline" }}
                href="https://zod.dev/"
              >
                Zod
              </a>{" "}
              mit{" "}
              <a
                style={{ textDecoration: "underline" }}
                href="https://github.com/esamattis/react-zorm"
              >
                react-zorm
              </a>{" "}
              und{" "}
              <a
                style={{ textDecoration: "underline" }}
                href="https://trpc.io/"
              >
                tRPC
              </a>{" "}
              und für Datensicherung/-sicherheit und Nachvollziehbarkeit eine
              private{" "}
              <a
                style={{ textDecoration: "underline" }}
                href="https://marcel-waldvogel.ch/anhalter/"
              >
                "Blockchain"
              </a>{" "}
              auf Basis von{" "}
              <a
                style={{ textDecoration: "underline" }}
                href="https://netfuture.ch/2022/02/git-pgp-blockchain-comparison/"
              >
                Zeitgitter-Zeitstempeln und git-Repos
              </a>{" "}
              mittels{" "}
              <a
                style={{ textDecoration: "underline" }}
                href="https://gitlab.com/zeitgitter/autoblockchainify#README"
              >
                autoblockchainify
              </a>
              . Alles in{" "}
              <a
                style={{ textDecoration: "underline" }}
                href="https://www.docker.com/"
              >
                Docker
              </a>{" "}
              unter{" "}
              <a
                style={{ textDecoration: "underline" }}
                href="https://de.wikipedia.org/wiki/Linux"
              >
                Linux
              </a>
              .
            </Text>
            <TryIt />
          </OuterBox>
          <OuterBox name="help.more">
            <Text width="100%" textAlign="left">
              Wollen Sie mehr wissen? Oder benötigen Sie etwas Ähnliches für
              Ihre Abläufe? Kontaktieren Sie uns{" "}
              <a
                style={{ textDecoration: "underline" }}
                href="https://trifence.ch/contact"
              >
                hier
              </a>
              .
            </Text>
          </OuterBox>
        </VStack>
      </Center>
      <Box height="5em" />
    </Box>
  );
}
