import { Box, Center, VStack } from "@chakra-ui/react";
import { eventSchema } from "shared";
import { DemoBox } from "./DemoBox";
import { EventList } from "./EventList";
import { RegistrationBox } from "./RegistrationBox";
import { UndoBox } from "./UndoBox";

export function AdminLanding() {
  return (
    <Center>
      <VStack
        marginTop="2rem"
        fontSize="2xl"
        width="100%"
        minHeight="80vh"
        height="100%"
        spacing={6}
      >
        <Box width="min(100vw, 40rem)" paddingX={2}>
          <DemoBox />
        </Box>
        <EventList />
        <UndoBox
          schema={eventSchema}
          name="eventUndo"
          describe={(data) => data.who}
          recoveryUrl="/recover"
        />
        <Box width="min(100vw, 40rem)" paddingX={2}>
          <RegistrationBox />
        </Box>
      </VStack>
    </Center>
  );
}
