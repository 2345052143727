"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eventSchema = exports.assigneeSchema = exports.roleSchema = exports.z = void 0;
const zod_1 = require("zod");
Object.defineProperty(exports, "z", { enumerable: true, get: function () { return zod_1.z; } });
const imageInfo_1 = require("./imageInfo");
const zodHelper_1 = require("./zodHelper");
exports.roleSchema = zod_1.z.enum([
    "demo",
    "guest",
    "master",
    "dept.1",
    "dept.2",
    "dept.3",
    "dept.4",
    "pub.1",
    "pub.2",
    "pub.3",
    "pub.4",
    "pub.5",
    "pub.6",
]);
exports.assigneeSchema = zod_1.z.enum([
    "new",
    "dept.1",
    "dept.2",
    "dept.3",
    "dept.4",
    "master",
    "pub",
]);
exports.eventSchema = zod_1.z.object({
    what: zod_1.z
        .string()
        .max(40)
        .refine((s) => s.length >= 10, { message: "Mindestlänge 10 Zeichen" }),
    who: zod_1.z
        .string()
        .max(33)
        .refine((s) => s.length >= 10, { message: "Mindestlänge 10 Zeichen" }),
    when: zod_1.z.array(zod_1.z.object({
        date: zod_1.z.string(),
        time: zod_1.z.string().min(5).transform(zodHelper_1.timesCleaner),
    })),
    where: zod_1.z
        .enum([
        "",
        "Keine Ahnung",
        "Märlihuus",
        "Bühne Rathausplatz",
        "Rathausplatz",
        "Kloster St. Georgen",
        "Schiffländi",
        "Andere",
    ])
        .refine((s) => s !== "", { message: "Auswahl benötigt" }),
    elsewhere: zod_1.z.string().optional(),
    img1: imageInfo_1.imageSchemaWithFile.refine((data) => data.file.length > 1, {
        message: "Bild wird benötigt",
    }),
    img2: imageInfo_1.imageSchemaWithFile.optional(),
    logo: imageInfo_1.imageSchemaWithFile.optional(),
    desc: zod_1.z.string().min(300).max(600),
    contact: zod_1.z.object({
        name: zod_1.z
            .string()
            .max(100)
            .refine((s) => s.length >= 6, { message: "Mindestlänge 6 Zeichen" }),
        tel: zod_1.z
            .string()
            .max(100)
            .transform((s) => {
            // Add spaces if none
            if (s.match(/^[0-9]{10}$/)) {
                s = [
                    s.substring(0, 3),
                    s.substring(3, 6),
                    s.substring(6, 8),
                    s.substring(8, 10),
                ].join(" ");
            }
            if (s.startsWith("00")) {
                return `+${s.substring(2)}`;
            }
            else if (s.startsWith("0")) {
                return `+41 ${s.substring(1)}`;
            }
            else {
                return s;
            }
        })
            .refine((s) => s.length >= 10, { message: "Mindestlänge 10 Zeichen" }),
        mail: zod_1.z
            .string()
            .max(100)
            .refine((s) => s.length >= 10, { message: "Mindestlänge 10 Zeichen" }),
        street: zod_1.z
            .string()
            .max(100)
            .refine((s) => s.length >= 6, { message: "Mindestlänge 6 Zeichen" }),
        town: zod_1.z
            .string()
            .max(100)
            .refine((s) => s.length >= 6, { message: "Mindestlänge 6 Zeichen" }),
        future: zodHelper_1.booleanCheckbox,
    }),
    comment: zod_1.z.string(),
    internal: zod_1.z
        .object({
        fee: zod_1.z.string().optional(),
        coupons: zod_1.z.string().optional(),
        travel: zod_1.z.string().optional(),
        notes: zod_1.z.string().optional(),
    })
        .optional(),
    // TODO: Those should be moved to a separate DB/separate DBs
    discussion: zod_1.z
        .array(zod_1.z.object({
        by: zod_1.z.string(),
        at: zod_1.z.number(),
        text: zod_1.z.string(),
        what: zod_1.z.enum(["assign"]).optional(),
    }))
        .optional(),
    message: zod_1.z.string().optional(),
    history: zod_1.z.array(zod_1.z.object({ by: zod_1.z.string(), at: zod_1.z.number() })).optional(),
    assignee: exports.assigneeSchema.optional(),
    published: zod_1.z.record(zod_1.z.number()).optional(),
});
