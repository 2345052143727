import { Button, Flex, Heading, Link, Text, VStack } from "@chakra-ui/react";
import { Link as RouteLink } from "react-router-dom";
import { useLogin } from "../contexts/LoginContext";
import { OuterBox } from "./OuterBox";
import { regularButtonProps } from "./RegistrationBox";

const roleDesc = {
  Gast: "Sie können neue Veranstaltungen erfassen, die nachher von der Koordination überprüft werden.",
  Koordination:
    "Sie haben zwei Aufgaben: 1. Grobe Überprüfung der Eingaben der Gäste an die Ressorts (Abt. Musik, Abt. C&W, …); 2. «Gut zum Druck» der Inhalte, bevor sie an die Publikatoren (Webseite, Layout, …) zur Veröffentlichung gesendet werden.",
  "Abt. Musik":
    "Sie koordinieren das Gesamtprogramm der Musik und verifizieren, ob die Angaben der eingeladenen Musiken stimmen.",
  "Abt. C&W":
    "Sie koordinieren das Gesamtprogramm der «Country and Western Days (C&W)» und verifizieren, ob die eingeladenen Veranstaltungen korrekt erfasst wurden.",
  Webseite:
    "Sie laden die Änderungen herunter («Download Programmpunkte») und veröffentlichen diese auf der Webseite maerlistadt.ch",
  Layout:
    "Sie laden die Änderungen herunter («Download Programmpunkte») und fügen diese ins Heft-Layout der Märlipost ein.",
};
type DescribedRoles = keyof typeof roleDesc;

export function DemoBox() {
  const [login, setLogin] = useLogin();

  const loginName = login?.name ?? "Gast";

  return (
    <OuterBox name="demo">
      <VStack width="100%" alignItems="left">
        <Text textAlign="left" lineHeight="110%">
          Dies ist eine Demoversion auf Demodaten. Ihre Änderungen werden
          regelmässig zurückgesetzt und gehen verloren.
          <Link
            paddingX={2}
            rounded="md"
            as={RouteLink}
            to="/help"
            textDecoration="underline"
          >
            Mehr…
          </Link>
        </Text>
        <Heading textAlign="left" fontSize="2xl" paddingTop={2}>
          Ihre aktuelle Rolle:{" "}
          <Text as="span" color="brand.400">
            {loginName}
          </Text>
        </Heading>
        {loginName in roleDesc ? (
          <Text textAlign="left" lineHeight="110%">
            {roleDesc[loginName as DescribedRoles]}
            <Link
              paddingX={2}
              rounded="md"
              as={RouteLink}
              to="/help#ablauf"
              textDecoration="underline"
            >
              Mehr…
            </Link>
          </Text>
        ) : null}
        <Heading textAlign="left" fontSize="2xl" paddingTop={2}>
          Schlüpfen Sie in eine andere Rolle:
        </Heading>
        <Flex width="100%" gap="2">
          {login ? (
            <Button
              onClick={() => setLogin(null)}
              {...regularButtonProps}
              margin="0"
            >
              Gast
            </Button>
          ) : null}
          {[
            ["Koordination", "69236199808411"],
            ["Abt. Musik", "33847232132628"],
            ["Abt. C&W", "28871073784700"],
            ["Webseite", "45078731245560"],
            ["Layout", "42812331205780"],
          ].map((e) => {
            if (login?.name === e[0]) {
              return null;
            } else {
              return (
                <Button
                  as={RouteLink}
                  to={`/login/${e[1]}`}
                  {...regularButtonProps}
                  margin="0"
                >
                  {e[0]}
                </Button>
              );
            }
          })}
        </Flex>
      </VStack>
    </OuterBox>
  );
}
