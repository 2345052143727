import { Center, Link, Spacer, Text, useToast, VStack } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router";
import { CenteredSpinner } from "../components/CenteredSpinner";
import { useLogin } from "../contexts/LoginContext";
import { trpc } from "../util/trpc";

export function Login() {
  const params = useParams<{ userId: string; role: string; name: string }>();
  const loginQuery = trpc.user.get.useQuery(params.userId);
  const [, setLogin] = useLogin();
  const toast = useToast();
  const history = useHistory();

  if (loginQuery.isLoading) {
    return <CenteredSpinner />;
  } else if (loginQuery.data) {
    setLogin(loginQuery.data);
    toast({
      status: "success",
      title: "Login erfolgreich",
      description: "Sie haben sich erfolgreich angemeldet",
    });

    history.push("/");
    return <Text>Zur Einstiegsseite</Text>;
  } else {
    return (
      <Center marginTop="5em" paddingTop="5em" width="100%">
        <VStack
          maxWidth="50em"
          width="80%"
          height="8em"
          rounded="xl"
          border="4px solid red"
          color="red"
        >
          <Spacer />
          <Text>Anmeldung nicht möglich</Text>
          <Spacer />
          <Text>
            {"Bitte konktaktieren Sie "}
            <Link href="mailto:info@trifence.ch">info@trifence.ch</Link>
          </Text>
          <Spacer />
        </VStack>
      </Center>
    );
  }
}
