import { Center, Image, Text, Box, VStack } from "@chakra-ui/react";
import logo from "../assets/logo.svg";
import { ExampleImages } from "../components/ExampleImages";
import { DemoBox } from "./DemoBox";
import { RegistrationBox } from "./RegistrationBox";

export function UserLanding(props: { thanks?: boolean }) {
  const { thanks } = props;
  return (
    <Center textAlign="center">
      <VStack
        width="min(100vw, 40rem)"
        paddingX={2}
        fontSize={{ base: "md", md: "xl" }}
        minHeight="100vh"
      >
        <DemoBox />
        {thanks ? (
          <VStack
            width="100%"
            rounded="lg"
            backgroundColor="brand.800"
            color="myellow.500"
            paddingY={6}
            spacing={6}
            marginBottom={12}
          >
            <Image src={logo} width="100%" />
            <Text fontSize="7xl" lineHeight="120%">
              Vielen Dank für Ihre Anmeldung!
            </Text>
            <Text paddingX={6} color="gray.200">
              Sie wird vom OK Märlistadt bei der nächsten Gelegenheit
              bearbeitet.
            </Text>
          </VStack>
        ) : null}
        <RegistrationBox thanks={thanks} />
        {thanks ? null : <ExampleImages />}
        <Box height="80vh" />
      </VStack>
    </Center>
  );
}
